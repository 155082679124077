import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import img_ from "./../assets/catalogs.jpg"

const data = [
    {
        "nombre":"Certificados",
        "pdfs":[
            {
                "pdf":"SEGITEC COLOMBIA SAS imp.pdf",
                "name":"Certificación Multigarben de distribuidor exclusivo"
            },
            {
                "pdf":"SEGITEC COLOMBIA - ACREDITACIÓN (003).pdf",
                "name":"Certificación Tecnored"
            }
        ]
    }
]


export default function Catalogs(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div className="w-full">
            <img className="w-full min-h-[300px] object-cover" style={{height:'50vh'}} src={img_}></img>
            
            <div className="px-[40px] sm:px-[50px] text-left pt-5 pb-10">
            <h1 className="text-3xl font-normal pb-2">Certificados</h1>
            <div className="flex flex-col md:flex-row justify-between">
            {
                data.map((d)=>
                    <div className="py-3">
                    {/*<p className="text-xl font-normal">{d.nombre}</p>*/}
                    <ul className="list-disc mx-5">
                        {d.pdfs.map((pdf)=>
                                <li><Link to={"/documentos/"+pdf.pdf} className="hover:text-blue-900 my-1">{pdf.name}</Link></li>
                        )}
                    </ul>
                    </div>)
            }
            </div>
            </div>
            
            {/**<iframe src="http://flowpaper.com/flipbook/https://arxiv.org/pdf/1904.10728.pdf"
            width="100%" height="800" className="w-full" allowFullScreen>
    </iframe>**/}
        </div>
    )
}