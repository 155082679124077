import React from "react";
import Carousell_Projects from "../Carousell_Projects";

export default function ProjectsPrincipal(){
    return(
        <div className="w-[100%] px-[40px] sm:px-[50px] text-left py-10">
            <div className="text-3xl font-normal">Proyectos destacados</div>
            <p className="text-xl font-light py-1 pb-4">En más de 10 años de experiencia hemos realizado proyectos destacados en dos contientes, consolidando nuestra propuesta de valor </p>
            <Carousell_Projects/>
        </div>
    )
}