import ProductHover from "../ProductHover";
import barandilla_img from "./../../assets/Products/proteccion_colectiva.png";
import dispositivo_img from "./../../assets/Products/Arnes.png";
import redes from "./../../assets/Products/redes_seguridad.png";

export default function ProductsPrincipal(){
    return(
        <div className="flex flex-wrap w-[100%] px-[40px] sm:px-[50px] py-10 justify-center sm:justify-between space-y-2">
            <div className="w-auto py-1 text-justify md:max-w-[45%] 2xl:max-w-[55%] md:pr-2">
                <p className="text-3xl font-normal ">Productos</p>
                <p className="text-xl font-light py-1">Tenemos una gran variedad de productos y servicios, somos líderes en protecciones colectivas, líneas de vida, puntos de anclaje y elementos de protección personal (EPP's) </p>
            </div>
            <div className="flex flex-col sm:flex-row  overflow-x-auto w-full md:w-[55%] 2xl:w-[45%] md:pl-2 gap-x-2 gap-y-2 justify-between">
                <ProductHover product_name={"Sistemas de protección colectiva"} src_img={barandilla_img} link={"/Productos/Sistemas_Colectivos"}/>
                <ProductHover product_name={"Sistemas de protección personal (EPP's)"} src_img={dispositivo_img} link={"/Productos/Sistemas_Individuales"}/>
                <ProductHover product_name={"Redes de seguridad"} src_img={redes} link={"/Productos/Redes_Otros_Propositos"}/>
            </div>
        </div>
    )
}