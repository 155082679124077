import React, { useEffect, useState } from "react";
import logo from "./../../assets/logo2.png"
import multigarben from "./../../assets/Exclusive/Multigarben.jpg"
import multigarben_i from "./../../assets/Exclusive/multigarben_instalador.jpg"
import multigarben_i2 from "./../../assets/Exclusive/multigarben_2.jpg"

const marcas =[logo,logo]
function Carousell_Exclusive(){
    const [activeIndex, setActiveIndex] = useState(0);
    const [hover,setHover]=useState(false);
    useEffect(() => {
        if(!hover){const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % marcas.length);
        }, 4000);
        return () => clearTimeout(timeout);}
    }, [activeIndex,hover]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex/marcas.length)*100}%)`,
        transition: "transform 1.5s ease", 
        display: "flex",
        width: `${200}%`,
    }
    return (
        <div className="w-full overflow-hidden relative" onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
            <div style={carouselStyle} >
                
                    <div className="w-full  bg-[#CFCFCF] py-2"> 
                        <p className="font-normal py-1 text-xl">SEGITEC COLOMBIA es REPRESENTANTE EXCLUSIVO EN COLOMBIA DE MULTIGARBEN</p>
                        <a target="_blank" rel="noreferrer" href="https://multigarben.com/"><img className="w-full min-h-[70px] max-h-[70px] object-contain mix-blend-color-burn items-center py-1" src={multigarben}></img></a>
                    </div>
                    <div className="w-full bg-[#CFCFCF] py-2"> 
                        <p className="font-normal py-1 text-xl">SEGITEC COLOMBIA es INSTALADOR AUTORIZADO de los productos MULTIGARBEN</p>
                        <a className="flex flex-row justify-center" target="_blank" rel="noreferrer" href="https://multigarben.com/">
                            <img className="w-auto min-h-[90px] max-h-[90px] object-contain mix-blend-color-burn items-center py-1" src={multigarben_i}></img>
                            <img className="w-auto min-h-[90px] max-h-[90px] object-contain mix-blend-color-burn items-center py-1" src={multigarben_i2}></img>
                        </a>
                    </div>
            </div>
        </div>
    )
}export default Carousell_Exclusive;