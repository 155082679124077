import React, {useEffect} from "react"
import Images from "../History/Images";
import C3 from "./../../assets/Products/Redes otros usos/futbol.jpg";
import C2 from "./../../assets/Products/Redes otros usos/golf.jpg"
import C1 from "./../../assets/Products/Redes otros usos/otros.png"

export default function RedesOtrosProp(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="px-[40px] md:px-[50px] text-left overflow-hidden">
            <Images C1={C1} C2={C2} C3={C3} contain={'contain1'}/>
            <h1 className="font-normal text-3xl py-5 text-left">Redes y cuerdas de seguridad para otros propósitos</h1>
            <p className="pb-4">Las redes de seguridad pueden emplearse fuera de los ámbitos industrial y constructivo para suministrar seguridad a usuarios de actividades recreativas que involucran riesgo, bien de caída de personas, o de objetos.</p>
            <hr></hr>
            <div className="pl-2 flex flex-col py-4 gap-y-1"> 
                <p className="font-normal text-2xl ">Canchas de fútbol</p>
                <p>En canchas de fútbol las redes de seguridad pueden ser empleadas para evitar que espectadores o transeúntes sean golpeados por la bola que sale del área de juego.</p>
                <br style={{display:"block", content:"", margin:"5px"}}/>
                <p className="font-normal text-2xl ">Campos de golf</p>
                <p>En los campos de golf y en los ranges de práctica pueden emplearse redes de seguridad para evitar que la bola de golf golpee viviendas o personas.</p>
                <br style={{display:"block", content:"", margin:"5px"}}/>
                <p className="font-normal text-2xl ">Glampings</p>
                <p>En los glampings y pistas de altura pueden emplearse redes de seguridad transitables, con tamaños y resistencias adecuadas para soportar a varias personas.</p>
                <br style={{display:"block", content:"", margin:"5px"}}/>
                <p className="font-normal text-2xl ">Otros usos para las redes - Redes especiales</p>
                <p>Los acuerdos comerciales de SEGITEC con sus proveedores le permiten suministrar redes de seguridad de tamaños y aberturas diversas pueden ser empleadas en katamaranes, para evitar el paso de pájaros en viviendas o edificaciones institucionales, etc. </p>
            
            </div>
        </div>
    )
}