import React,{useEffect} from "react";
import img_ from "./../assets/normativa.jpg";
const regulacion = [
    {normatividad:'RESOLUCIÓN 4272-2021 ',
    contenido:'Reglamenta Trabajo en Alturas',
    url:'https://www.cancilleria.gov.co/sites/default/files/Normograma/docs/resolucion_mtra_4272_2021.htm'},
    {normatividad:'ANSI Z359.1 ',
    contenido:'Aborda los medios de prevención de caídas laborales y protección contra caídas.',
    url:'http://s7d9.scene7.com/is/content/minesafetyappliances/2302-27-sp_ansi-updates'},
    {normatividad:'OSHA – 1926 Subpart M ',
    contenido:'Reglamento de Seguridad y Salud en la Construcción, Protección contra caídas',
    url:'https://www.osha.gov/laws-regs/regulations/standardnumber/1926/1926SubpartM '},
    {normatividad:'OSHA - 1926.501 ',
    contenido:'Reglamento de Seguridad y Salud en la Construcción,  establece los requisitos para que los empleadores proporcionen sistemas de protección contra caídas',
    url:'https://www.osha.gov/laws-regs/regulations/standardnumber/1926/1926.501'}
    ,{normatividad:'OSHA - 1926.502',
    contenido:'Criterios y prácticas de los sistemas de protección contra caídas.',
    url:'https://www.osha.gov/laws-regs/regulations/standardnumber/1926/1926.502'},
    {normatividad:'UNE-EN 795:2012 ',
    contenido:'Especifica los requisitos, los métodos de ensayo, las instrucciones y el marcado de los dispositivos de anclaje, destinados a ser utilizados con Equipos de Protección Individual contra las caídas de altura.',
    url:'https://www.une.org/encuentra-tu-norma/busca-tu-norma/norma?c=N0049822'},
    {normatividad:'UNE-EN 1263',
    contenido:'Norma que determina los requisitos que tienen que cumplir todas las redes de seguridad que se fabrican en instalan dentro de la Unión Europea y otros territorios adscritos.',
    url:'https://www.une.org/encuentra-tu-norma/busca-tu-norma/norma?c=N0060704'},
    {normatividad:'UNE- EN 13374',
    contenido:'Características de los sistemas provisionales de protección de borde vienen determinadas por la norma UNE-EN 13374:2004. Dichos sistemas se emplean para prevenir la caída de personas y objetos a un nivel más bajo desde tejados, bordes, escaleras y otras áreas donde se requiera protección.',
    url:'https://www.une.org/encuentra-tu-norma/busca-tu-norma/norma?c=N0051910'}
]


export default function Regulations(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div >
            <img className="w-full min-h-[300px] object-cover" style={{height:'50vh'}} src={img_}></img>
            <div className="px-[40px] sm:px-[50px] py-5 text-left">
            <p className="text-3xl font-normal  pt-2">Normatividad</p>
            <p className="py-4">La legislación vigente para el trabajo en alturas, instalación de líneas de vida y puntos de anclaje es:</p>
            <table className="hidden sm:table w-full  border-separate border-spacing-y-2">
                <tr className="bg-gray-200 text-lg text-center">
                    <th>Normativa</th>
                    <th>Descripción</th>
                    <th className="px-2">Enlace</th>
                </tr>
                {regulacion.map((e)=>
                    (<tr className="odd:bg-gray-50 mb-2">
                        <td>{e.normatividad}</td>
                        <td>{e.contenido}</td>
                        <td><a href={e.url} className="hover:font-normal text-blue-900 mx-2" target="__blank">Ver</a></td>
                    </tr>)
                )}
                
            </table>
            <table className="sm:hidden w-full text-left border-separate border-spacing-y-2">
                <tr className="bg-gray-200 text-lg text-center ">
                    <th>Normativa</th>
                    <th>Descripción</th>
                </tr>
                {regulacion.map((e)=>
                    (<tr className="odd:bg-gray-50 mb-2">
                        <td className="">
                            <p>{e.normatividad}</p>
                            <p><a href={e.url} className="hover:font-normal text-blue-900" target="__blank">Ver</a></p>
                        </td>
                        <td>{e.contenido}</td>
                    </tr>)
                )}
            </table>
            </div>
        </div>
    )
}