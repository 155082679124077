import React, { useEffect, useState } from "react";
import logo from "./../../assets/logo2.png"
import Video from "./../../assets/Products/Redes seguridad/RENDER.mp4"

const images = require.context('./../../assets/Carousell', true);
const imageList = images.keys().map(image => images(image));
{/*Pendiente seleccion de imagenes y establecer ruteo*/}
function Carousell(){
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [activeIndex, imageList.length]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / imageList.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out",
        display: "flex",
        width: `${imageList.length * 100}%`,
    }
    return (
        <div className="w-full min-h-[350px] max-h-[500px] overflow-hidden relative" style={{height:'60vh'}}>
            {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
            {/*<div style={carouselStyle}>
                {imageList.map((p)=><img className="w-full h-[420px] object-cover" style={{ flex: `0 0 ${100 / imageList.length}%` }} src={p}></img>)}
            </div>*/}
            <video className="w-full h-full object-cover"  height="240" autoPlay loop> 
                <source src={Video} type="video/mp4" autoPlay/>
            </video>
            <div className="absolute grid grid-cols-1 w-full h-full z-100 top-0 text-white justify-items items-center place-content-center" style={{backgroundColor:"rgba(255,255,255,0.5)"}}>
                <img className="display-block ml-auto mr-auto pb-10 h-[250px]" src={logo}/>
                <p className="w-[80%] ml-auto mr-auto text-xl sm:text-2xl text-black">Somos expertos en el diseño, instalación y manejo de sistemas de protección, redes y equipos de seguridad. Tenemos más de 10 años de experiencia en España y Colombia. </p>
            </div>
            
        </div>
    )
}export default Carousell;