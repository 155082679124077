import React ,{useState, useEffect}from "react";

export default function Carousell_Products({products,contain}){
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % products.length);
        }, 4000);

        return () => clearTimeout(timeout);
    }, [activeIndex, products.length]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / products.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out",
        display: "flex",
        width: `${products.length * 100}%`,
    }
    return (
        <div className="w-full h-[200px] overflow-hidden object-cover mb-2">
            <div className="w-full overflow-hidden items-center">
                <div className=""style={carouselStyle}>
                    {products.map((p)=>
                       { return (<div className="relative" style={{ flex: `0 0 ${100 / products.length}%` }}>
                            <img className={` w-full h-[200px] object-${contain?'contain':'cover'}`}  src={p.src}></img>
                            {p.name&&<p className="absolute w-full bottom-0  font-normal text-lg text-left pl-10 py-4 bg-white/80">{p.name}</p>}
                        </div>)}
                    )}
                </div>
            </div>
        </div>
    )
}