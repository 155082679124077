import React, {useEffect} from "react";
import img_ from "./../assets/workwus.png"
import { FaWhatsapp } from "react-icons/fa6";
export default function WorkUs(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div className="">
            <img className="w-full min-h-[300px] object-contain" style={{height:'50vh'}} src={img_}></img>
            <div className="px-[40px] sm:px-[50px] text-left pt-5 pb-10">
            <p className="text-3xl font-normal py-2">Trabaja con nosotros:</p>
            <p className="text-lg pb-2">
            SEGITEC S.A.S. siempre está buscando gente competente, comprometida con el trabajo; gente a la que le guste lo que hace; gente competente. Si quiere trabajar con nosotros, mande su hoja de vida a <a href="mailto:empleos@segitec.com.co" className="text-blue-800 hover:text-blue-900">empleos@segitec.com.co</a>    
            o por medio de WhatsApp
            </p>
            <div className="flex flex-row w-full justify-center">
            <a href={`https://wa.me/+573174235983`} target="__blank"  className='flex w-[400px] max-w-[500px] text-lg rounded-3xl bg-blue-900 hover:bg-[#075E54] text-white font-normal justify-center sm:space-x-2 items-center hover:shadow-md shadow-[#128C7E]'>
                  <p className='mx-5 my-2'>Enviar hoja de vida</p>
            </a>
            </div>
            </div>
        </div>
    )
}