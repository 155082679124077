import React, {useEffect,useState} from "react";
import Img_CesarA70 from "./../../assets/Products/Nueva carpeta/CesarA70.jpg";
import Img_CesarA85 from "./../../assets/Products/Nueva carpeta/CesarA85.jpg";
import Img_MultiBA1 from "./../../assets/Products/Nueva carpeta/MultigarbenBA1.jpg";
import Img_Ana500 from "./../../assets/Products/Nueva carpeta/Ana500.jpg";
import {BsFillImageFill,BsTextCenter} from "./../../../node_modules/react-icons/bs"

const htmls=[
    {nombre:"DISPOSITIVO DE ANCLAJE PARA FIJACIONES VERTICALES CESAR A70",
    html:(
        <p>
        CESAR A70, son dispositivos de anclaje conforme a la norma europea EN 795 tipo A.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Diseñado con cuerda de poliamida de alta tenacidad industrial Ø11mm (longitud 700mm +5mm), que fijado mediante anclaje metálico a estructuras de hormigón armado, pilares, paramentos verticales de hormigón fisurado/no fisurado, armado u otros elementos particularmente en obras de construcción son utilizados para la sujeción de arneses de seguridad EN361 con sistemas de amarre EN354 / EN355 y EN358.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Están confeccionados con cuerdas que presentan en uno de sus extremos una gaza unida a un anclaje estructural de acero tipo cáncamo M12x170mm, y otra gaza en el extremo opuesto para la fijación de conectores conforme a UNE EN 362.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Disponible en dos longitudes estándar de 0.7m y 2m. PUEDEN SER FABRICADOS EN MEDIDAS ESPECIALES.
        <br style={{display:"block", content:"", margin:"10px"}}/>
        </p>
    ),
    src:Img_CesarA70},
    {nombre:"DISPOSITIVO DE ANCLAJE PARA FIJACIONES HORIZONTALES CESAR A85",
    html:(
        <p>
        CESAR A85, son dispositivos de anclaje conforme a la norma europea EN 795:2012 tipo A, con fijación estructural (taco metálico de expansión) diseñados con cuerda de poliamida de alta tenacidad industrial Ø11mm, que fijados a estructuras portante de hormigón fisurado/no fisurado, armado, vigas u otros elementos particularmente en obras de construcción son utilizados para la sujeción de arneses de seguridad EN361 con sistemas de amarre EN354 / EN355 y EN358.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Están confeccionados con cuerdas que presentan en uno de sus extremos una gaza unida a un cáncamo de acero M12x110mm conforme a DIN 582, y otra en el otro extremo para la fijación de conectores conforme a UNE EN 362.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Disponible en longitudes estándar de 0.85m, 1.5m, 2m, 2.5m, 3.5m y 4.5m. PUEDEN SER FABRICADOS EN MEDIDAS ESPECIALES.
        <br style={{display:"block", content:"", margin:"10px"}}/>
        </p>
    ),
    src:Img_CesarA85},
    {
        nombre:"DISPOSITIVO DE ANCLAJE MultiGarBen BA1",
        html:(
        <p>
        El dispositivo de anclaje MultiGarBen BA1 es un dispositivo de sujeción temporal portátil en conformidad con EN 795:2012 Tipo B para 1 persona como máximo. Debe utilizarse únicamente de acuerdo con su uso previsto y respetando las condiciones de utilización especificadas. Los trabajos de modificación, alteración y reparación únicamente pueden ser realizados por el fabricante.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Estos dispositivos de anclaje han sido diseñados conforme a los requerimientos de la norma europea EN 795:2012 “Protección contra caídas de altura. Dispositivos de anclaje. Requisitos y ensayos” especificados en el Tipo B que, fijados a pilares, vigas u otros elementos resistentes son utilizados como anclaje multifuncional para la sujeción de otros sistemas de seguridad como líneas de anclaje de uso horizontal para formar sistemas conforme a norma EN 795:2012.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Los sistemas MultiGarBen BA1 están confeccionados con una cinta que presenta en uno de sus extremos un tensor regulable con un mecanismo de bloqueo antiretorno, donde se acopla el otro extremo libre de dicha cinta, para colocarla alrededor de un punto de fijación, procediendo posteriormente a tensarla hasta inmovilizarla en la posición deseada.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        La cinta está confeccionada con fibras de poliéster de alta tenacidad industrial (ancho 50mm), de una gran flexibilidad y resistencia a la tracción y a la cizalladura, soportando las tensiones propias de los elementos de seguridad, incluido el peso de los operarios cuando realizan trabajos suspendidos o colgados de este sistema de anclaje.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        En el diseño se ha previsto que dicha cinta presente en las zonas intermedias un doble cuerpo, para la realización de ventanas o vainas de estabilización donde son introducidos los conectores/anilla cerrada de anclaje, incluso una vez apretada la cinta alrededor del punto fuerte de ubicación.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        También se ha dispuesto que esta cinta disponga de varias trabillas salva-esquinas que definen un ojal ligeramente mayor que el ancho de la cinta para permitir su desplazamiento a lo largo de la misma, así como una prolongación, a modo de empuñadura por la que se pueden asir para posicionarlas en las esquinas (por ejemplo, en pilares), de forma que una vez situadas protejan la cinta de las rozaduras propias de dichas esquinas.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Disponible en longitudes estándar de 2m, 2.5m, 3m, 4m, 5m y 7m. PUEDEN SER FABRICADOS EN MEDIDAS ESPECIALES.
        <br style={{display:"block", content:"", margin:"10px"}}/>
        </p>
        ),
        src:Img_MultiBA1
    },
    {
        nombre:"CINTA ANILLADA ANA SERIE 500",
        html:(
        <p>
        Los dispositivos de anclaje ANA serie 500 son sistemas de seguridad transportables, dotados con gazas cada 1.5 m para formar puntos fijos donde puedan sujetarse personas que vayan provistas de equipos de protección contra caídas en altura Han sido diseñados conforme a los requisitos de la norma europea EN 795 2012
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Dispone de gaza terminal para su fijación a dispositivos estructurales del tipo A y/o transportables del tipo B
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Diseño y compatibilidad:
        <br/>
        Son dispositivos de anclaje fabricados con cinta de poliéster de alta tenacidad industrial, que pueden ser fijados mediante anclajes estructurales metálicos y/o dispositivos transportables MultiGarBen a paramentos horizontales/verticales de hormigón fisurado, no fisurado, armado, pilares u otros elementos particularmente en obras de construcción e industrial
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Son utilizados para la conexión de arneses de seguridad EN 361 con sistemas de amarre EN 354 / EN 355 / EN 358 y conectores EN 362
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Cinta guía con gazas para formar puntos de anclaje fijos cada 1.5 m.
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Uso
        <br/>
        Evitar enredos para tener mayor agilidad de movimientos en el plano de trabajo y/o prevención de accidentes
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Los puntos de anclaje deberán estar por encima del centro de gravedad de las personas usuarias
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Es bastante común utilizar cintas guía para posicionamientos sobre cubiertas inclinadas, aunque alcanza su mayor fundamento en la protección de personas sobre plataformas mecanizadas (mono y bimástiles)
        <br style={{display:"block", content:"", margin:"5px"}}/>
        Disponible en longitudes estándar de 5m, 10m, 15m, 20m, 25m, 30m y 40m. PUEDEN SER FABRICADOS EN MEDIDAS ESPECIALES.
        <br style={{display:"block", content:"", margin:"10px"}}/>
        </p>
        ),
        src:Img_Ana500
    }
]

export default function Dispositivos(){
    const [activeIndex, setActiveIndex] = useState(0);
    const [showImages, setShowImages] = useState(0);
    useEffect(() => {
        setShowImages(0)
    }, [activeIndex]);
    useEffect(()=>{

    },[showImages])

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / htmls.length) * 100}%)`,
        transition: "transform 0.5s ease-in-out", 
        width: `${htmls.length * 100}%`,
    }

    const fliped ={
        transform:`rotateY(${showImages}deg)`,
        transformStyle: 'preserve-3d', 
        transition: "transform 0.5s ease-in-out",
    }
    return(
        <div className="flex w-full lg:w-[50%]  lg:pl-2 rounded-lg">
            <div className="w-auto h-auto overflow-hidden  relative">
                {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
                <div style={carouselStyle} className="flex flex-row items-start">
                    {htmls.map((p)=>
                            <div className="relative w-full top-0 flex flex-row items-start">
                                <div className="relative" style={fliped}>
                                        <div className={`absolute top-0 bottom-0 right-0 left-0 px-3 sm:px-10 bg-white h-full rounded-lg border-b-4 border-t border-l-2 border-r-2 text-center overflow-hidden`} style={{backfaceVisibility:'hidden'}}>
                                            <div className="px-3 grid grid-cols-1 my-2 sm:px-10 min-h-[340px] max-h-[340px] content-evenly overflow-hidden">
                                                <h1 className="text-md sm:text-lg md:text-xl font-normal text-center bg-white px-3 sm:px-10  ">{p.nombre}</h1>
                                                <img className="w-full mb-5 min-h-[165px] max-h-[250px] object-contain bottom-0" src={p.src}></img>
                                            </div>
                                            <button className={`absolute top-4 right-5 text-black/80 font-normal hover:text-black `} onClick={()=>setShowImages((prev)=>(prev+180)%360)}>
                                                <BsTextCenter size={28}/>
                                            </button>
                                        </div>
                                        <div className="px-3 sm:px-10 py-5 min-h-[340px] max-h-[340px] bg-gray-700 rounded-lg " style={{transform:`rotateY(180deg)`,backfaceVisibility:'hidden'}}>
                                            <h1 className="text-md sm:text-lg md:text-xl font-normal text-center text-white mx-6 px-3 mb-1">{p.nombre}</h1>
                                            <p className="sm:text-md px-7 sm:px-0 md:text-lg text-white h-[190px] sm:h-[280px] overflow-y-auto ">{p.html}</p>
                                            <button className={`absolute top-4 right-10 text-white/90 font-normal hover:text-white w-[10px]`} onClick={()=>setShowImages((prev)=>(prev+180)%360)}>
                                                <BsFillImageFill size={28}/>
                                            </button>
                                        </div>
                                        
                                        <button className={`absolute left-3 top-0 bottom-0 text-4xl ${showImages===0?'text-black':'text-white'}`} onClick={()=>setActiveIndex((prevIndex) => (prevIndex + (showImages===0?-1:1)) % htmls.length)}>{"<"}</button>
                                        
                                        <button className={`absolute right-3 top-0 bottom-0 text-4xl ${showImages===0?'text-black':'text-white'}`} onClick={()=>setActiveIndex((prevIndex) => (prevIndex + (showImages===0?1:-1)) % htmls.length)}>{">"}</button>
                                        
                                    </div>
                            </div>
                        )}{console.log(htmls)}
                </div>
                </div>
        </div>
    )
}