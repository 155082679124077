import React, {useEffect} from "react";
import Img_LineasDefinitivas from "./../../assets/Products/pendiente.jpg";
import Img_LineasTemporales from "./../../assets/Products/linea_temporal.jpg";
import Img_Escaleras from "./../../assets/Products/EscalerasDeGato.png";
import Img_Barandilla from "./../../assets/Products/barandilla.jpg";
import Img_PuntoAnclaje from "./../../assets/Products/puntos_anclaje_definitivos.jpg";
import Video from "./../../assets/Products/Redes seguridad/RENDER.mp4"
import Images_red from "./Images_red";
import Carousell_Products from "./Carousell_Products";

import Img_Linea from "./../../assets/Products/Lineas de vida/LineaFlexible.png";
import Img_LineaCarro from "./../../assets/Products/Lineas de vida/LineaFlexibleCarro.png";
import Img_LineaRigida from "./../../assets/Products/Lineas de vida/LineaRigida.png";

import Img_Punto1 from "./../../assets/Products/PuntosAnclaje/Punto1.png";
import Img_Punto2 from "./../../assets/Products/PuntosAnclaje/Punto2.png";
import Img_Punto3 from "./../../assets/Products/PuntosAnclaje/Punto3.png";

const pLineas = [
    {
        src:Img_Linea,
        name: 'Línea de vida definitiva flexible para 6 personas'
    },
    {
        src:Img_LineaCarro,
        name: 'Línea de vida flexible con carro para 6 personas'
    },
    {
        src:Img_LineaRigida,
        name: 'Línea de vida rígida para 6 personas'
    }
]

const pPuntos=[{src:Img_Punto1},{src:Img_Punto2},{src:Img_Punto3}]
export default function SistemasColectivos(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className=" text-left overflow-hidden">
            <video className="w-full h-[300px] object-cover"  height="240" autoPlay loop> 
                <source src={Video} type="video/mp4" autoPlay/>
            </video> 
            <div className="px-[40px] md:px-[50px]">
                <h1 className="font-normal text-3xl py-5 text-left">Sistemas de protección colectivos</h1>
                <div className="pl-2 flex flex-wrap py-4"> 
                    <div className="w-full md:w-[50%] md:pr-2">
                        <p className="font-normal text-2xl ">Redes de seguridad</p>
                        <p className="">Las redes que usa y distribuye SEGITEC provienen de fábricas españolas quienes prueban y certifican los materiales y la fabricación de acuerdo con lo señalado en la norma UNE-EN 1263-1:2015. Los SISTEMAS DE PROTECCIÓN COLECTIVA han sido clasificados como SISTEMA V; SISTEMA U; SISTEMA T o SISTEMA DE MAMPARA o SISTEMA TIPO T.</p>
                    </div>
                    <Images_red />
                </div>
                <hr></hr>
                <div className="pl-2 py-4"> 
                    <div className="w-full">
                        <p className="font-normal text-2xl ">Líneas de vida</p>
                        <p className="">Una "línea de vida" es un sistema de seguridad colectivo o individual utilizado en entornos laborales, industriales y deportivos para prevenir caídas desde alturas elevadas. Consiste en una serie de componentes como cables, cuerdas o rieles que están anclados de manera segura en estructuras o soportes.</p>
                    </div>
                    <div className="flex flex-wrap w-full py-4">
                        <div className="flex flex-col w-full md:w-[50%] md:pr-2 pb-1 md:pb-0">
                            <Carousell_Products className="w-full h-[200px] object-cover mb-2 overflow-hidden" products={pLineas} contain={false}></Carousell_Products>
                            <p className="font-normal text-xl">Lineas de vida definitivas</p>
                            <p className="">Las líneas de vida definitivas que suministra, instala y certifica SEGITEC son fabricados por <a href="https://multigarben.com/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">MULTIGARBEN
                            </a>, que es el más importante fabricante español y uno de los más importantes de Europa. Estas líneas de vida tienen las certificaciones UNE-EN 795:2012 tipo C - CEN/TS 16415:2013 para la <a href="https://creacionespreventivas.com/lineas-de-anclaje-flexibles-definitivas-al6-f/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">línea de vida definitiva flexible
                            </a> y UNE-EN 795:2012 tipo D - CEN/TS 16415:2013 para la <a href="https://creacionespreventivas.com/linea-de-anclaje-rigida-al6r/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">línea de vida rígida definitiva</a>.</p>
                        </div>
                        <div className="flex flex-col w-full md:w-[50%] md:pl-2 pt-1 md:pt-0">
                            <img className="w-full h-[200px] object-cover mb-2" src={Img_LineasTemporales}></img>
                            <p className="font-normal text-xl">Lineas de vida provisionales</p>
                            <p className="">Las líneas de vida provisionales son la mejor opción para la realización de trabajos en altura de corto plazo. Las líneas de vida que distribuye SEGITEC son fabricadas por <a href="https://multigarben.com/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">MULTIGARBEN
                            </a> y su buque insignia es la <a href="https://gharo.es/dispositivos-y-lineas-de-anclaje-textiles-2/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">LUISA SERIE 500</a>.</p>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="flex flex-wrap pl-2 py-4"> 
                    <div className="w-full md:w-[50%]">
                        <p className="font-normal text-2xl">Escaleras de gato</p>
                        <p className="">Son escalerillas de seguridad diseñadas y fabricadas para garantizar para el acceso seguro a lugares en los que no hay estructuras definitivas en concreto.</p>
                    </div>
                    <img className="w-full md:w-[50%]  h-[200px] object-contain md:pl-4"src={Img_Escaleras}></img>                
                </div>
                <hr></hr>
                <div className="flex flex-wrap pl-2 py-4"> 
                    <div className="w-full">
                        <p className="font-normal text-2xl">Barandillas</p>
                    </div>
                    <div className="flex flex-wrap w-full py-4">
                        <div className="flex flex-col w-full md:w-[50%] md:pr-2 pb-1 md:pb-0">
                            <p className="font-normal text-xl">Barandillas definitivas</p>
                            <p className="">Las barandillas de seguridad pueden ser provisionales o definitivas. Las provisionales que usamos en obra previenen la caída desde los bordes de las placas en construcción en edificios, así como la caída a fosos y claustros que durante el proceso constructivo se encuentran abiertos..</p>
                        </div>
                        <div className="flex flex-col w-full md:w-[50%] md:pl-2 pt-1 md:pt-0 space-y-1">
                            <p className="font-normal text-xl">Barandillas provisionales</p>
                            <p className="">Fabricadas por <a href="https://creacionespreventivas.com/barandillas/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">MULTIGARBEN</a> bajo la norma UNE-EN ISO 14122-3..</p>
                            <div className="flex flex-wrap">
                                <img className="w-full lg:w-[50%] h-[200px] object-contain mb-2" src={Img_Barandilla}></img>
                                <p className="w-full lg:w-[50%]">Estas barandillas pueden ser RECTAS o INCLINADAS con opción de ser contrapesada, sobre cubierta frágil, sobre hormigón, curva recta contrapesada, curva recta sobre cubierta frágil, curva recta sobre hormigón</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="flex flex-wrap pl-2 py-4"> 
                    <div className="w-full md:w-[50%] mb-4">
                        <p className="font-normal text-2xl ">Puntos de anclaje definitivos</p>
                        <p className="">Los puntos de anclaje que suministra SEGITEC de manera exclusiva en Colombia, son fabricados por <a href="https://creacionespreventivas.com/dispositivos-de-anclaje-definitivos/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">MULTIGARBEN
                            </a> bajo la norma UNE-EN 795:2012 tipo A / tipoB y la especificación técnica CEN/TS 16415:2013.</p>
                    </div>
                    <div className="w-full md:w-[50%] mb-4">
                    <Carousell_Products products={pPuntos}contain={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}