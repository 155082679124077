import React, { useState } from "react";
import img from "./../assets/logo2.png";
import img2 from "./../assets/LOGO_EXPERIENCIA.png";
import { Link } from "react-router-dom";
const links=[
    {name:'Inicio',link:"/"},
    {name:'Productos',link:"/productos"},
    {name:'Nosotros',link:"/Nosotros"},
    {name:'Experiencia',link:"/experiencia"},
    {name:'Normatividad',link:"/normatividad"},
    {name:'Catálogos',link:"/catalogos"},
    {name:'Trabaja con nosotros',link:"/Trabaja_con_nosotros"}
]
function Navbar(){
    const [hamburger, setHamburger] =useState(false);
    return(
        <nav className="sticky top-0 flex justify-between items-center px-8 sm:px-12 w-full h-20 z-10 bg-[#F0F0F0]">
            <Link to={'/'}><img className="items-center h-[60px] min-w-[93px] object-contain" src={img} alt="Segitec Colombia S.A.S"/></Link>
            
            <div className="hidden lg:flex justify-between items-center">
                {links.map((e)=>
                <Link to={e.link} className="mx-5"><p className="text-gray-600 hover:font-normal hover:text-gray-900">{e.name}</p></Link>
            )}
            </div>
            <div className="items-center">
                <Link className="hidden lg:flex" to={'/'}><img className="items-center h-[75px] min-w-[93px] object-contain" src={img2} alt="Segitec Colombia S.A.S"/></Link>
                <button className="flex lg:hidden" onClick={()=>setHamburger((prev) => !prev)}>
                    <div className="flex flex-col space-y-1">
                    <div className="h-[5px] w-[30px] bg-blue-900"></div>
                    <div className="h-[5px] w-[30px] bg-blue-900"></div>
                    <div className="h-[5px] w-[30px] bg-blue-900"></div>
                    </div>
                </button>
            </div>
            {hamburger &&(
                <div className="lg:hidden absolute top-[80px] flex flex-col right-0 bg-[#F0F0F0]/95 w-full  py-2 space-y-1">
                    {links.map((e)=>
                        <Link to={e.link} className="group/item hover:bg-gray-200" onClick={()=>setHamburger(false)}><p className="text-xl text-gray-600 group-hover/item:font-normal group-hover/item:text-gray-900">{e.name}</p></Link>
                    )}
                    <button className="group/item"><a className="text-xl text-gray-600 group-hover/item:font-normal group-hover/item:text-gray-900">{/*Versión en Ingles*/}</a></button>
                </div>
            )}
        </nav>
        
    )
}export default Navbar;