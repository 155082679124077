import React, { useEffect, useState } from "react";



/*Pendiente completar las marcas según la pptx*/
const marcas=[{ nombre:"amarilo.jpg", src:"https://amarilo.com.co"},
    {nombre:"ariassernasaravia.jpg", src:"https://www.ariassernasaravia.com.co"},
    {nombre:"constructorabolivar.jpg", src:"https://www.constructorabolivar.com"},
    {nombre:"constructoracapital.jpg",src:"https://www.constructoracapital.com"},
    {nombre:"cusezar.jpg",src:"https://cusezar.com"},
    {nombre:"decosa.jpg",src:"http://www.decosa.net"},
    {nombre:"deco.jpg",src:"https://www.fundaciongruposocial.co"},
    {nombre:"constructoraexperta.jpg",src:"https://www.constructoraexperta.com"},
    {nombre:"solerium.jpg",src:"https://www.gruposolerium.com"},
    {nombre:"kma.jpg",src:"https://kma.com.co"},
    {nombre:"mensula.jpg",src:"https://mensula.com"},
    {nombre:"novasis.jpg",src:"https://www.novasis.cl"},
    {nombre:"obreval.jpg",src:"http://www.obreval.com"},
    {nombre:"suprema.jpg",src:"https://www.supremainmobiliaria.com"},
    {nombre:"triada.jpg",src:"https://triada.com.co "}
]
/*Pendiente seleccion de imagenes y establecer ruteo*/
function Carousell_Marcas(){
    const [activeIndex, setActiveIndex] = useState(0);
    const [hover,setHover]=useState(false);
    useEffect(() => {
        if(!hover){const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 0.5) % 100);
        }, 300);
        return () => clearTimeout(timeout);}
    }, [activeIndex,hover]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex)}%)`,
        transition: "transform 0.6s ease", 
        display: "flex",
        width: `${100}%`,
    }
    return (
        <div className="w-full h-[144px] overflow-hidden relative py-2 " onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
            <div style={carouselStyle} >
                {marcas.map((p)=>
                    <div className="min-w-[144px] h-[144px] mx-1 sm:mx-3 md:mx-5 my-[2px] bg-[#D9D9D9] pt-1"> 
                        <a href={p.src} target="_blank" rel="noreferrer"><img className="min-w-[140px] max-w-[140px] min-h-[110px] max-h-[110px] aspect-[3/2] object-contain mix-blend-color-burn items-center" src={"./Marcas/"+p.nombre}></img></a>
                    </div>)}
            </div>
        </div>
    )
}export default Carousell_Marcas;