import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Contact from "./Contact";

const contact_info = [
   {  
      country:"Colombia",
      location:"Calle 98 No. 51 A 37 – Oficina 401 C.P. 111221, Bogotá (Colombia)",
      number:"+57 316 304 2684",
      mail:"comercialcolombia@segitec.com.co",
      whatsapp:"+57 316 304 2684",
      mail2:"servicioalcliente@segitec.com.co",
      mail3:"serviciotecnico@segitec.com.co",
      number2:"+57 321 310 3089"
   }
]
function Footer(){
    return (
        <div className="flex flex-wrap bg-[#F0F0F0] w-100 px-[40px] sm:px-[50px] text-left pt-5 justify-between pb-[25px] gap-x-2">
             <div className="flex flex-col w-auto my-2 max-w-[250px] space-y-1">
               <Link to={"/Productos"} className="font-normal">Productos</Link>
                <Link to={"/Productos/Sistemas_Colectivos"}><p className="hover:text-blue-900">Sistemas de protección colectiva</p></Link>
                <Link to={"/Productos/Sistemas_Individuales"}><p href="" className="hover:text-blue-900">Sistemas de protección individual (EPP's)</p></Link>
                <Link to={'/Productos/Redes_Otros_Propositos'}><p href="" className="hover:text-blue-900">Redes y cuerdas de seguridad para otros propósitos</p></Link>
             </div>
             <div className="flex flex-col w-auto my-2 max-w-[250px] space-y-1">
                <Link to={"/Nosotros"} className="font-normal">Compañía</Link>
                <Link to={"/Nosotros"} ><p className="hover:text-blue-900">Sobre nosotros</p></Link>
                <Link to={"/Experiencia"} ><p className="hover:text-blue-900">Experiencia</p></Link>
                <Link to={"/Normatividad"} className="hover:text-blue-900">Normatividad</Link>
                <Link to={"/Trabaja_con_nosotros"} className="hover:text-blue-900">Trabaja con nosotros</Link>
                <Link to={"/PQRs"} className="hover:text-blue-900">PQRs</Link>
             </div>
             <div className="flex flex-col w-auto my-2 max-w-[250px] space-y-1">
                <Link to={"/Catalogos"} className="font-normal">Catálogos</Link>
                <Link to={"/Catalogos"} className="hover:text-blue-900">Brochure</Link>
                <Link to={"/Certificados"} className="hover:text-blue-900">Certificaciones</Link>
                <Link to={"/Catalogos"} className="hover:text-blue-900">Fichas técnicas</Link>
             </div>
             <div className="flex flex-col w-auto my-2 space-y-1 overflow-hidden">
                <p className="font-normal w-[300px]">Contactenos</p>
                {contact_info.map((e)=>{return <><Contact location={e.location} name={e.name} number={e.number} mail={e.mail} number2={e.number2} mail2={e.mail2} mail3={e.mail3} whatsapp={e.whatsapp}/><hr></hr></>})}
             </div>
             <div className="flex flex-row w-full mt-2 space-x-2 justify-center">
               
               <a className="hover:text-blue-900 text-center" href="/ACUERDO DE ACCESO SEGITEC COLOMBIA 20-19-2023.pdf" target="__blank">Acuerdo de acceso</a>
               <p>|</p>
               <a className="hover:text-blue-900 text-center" href="/CONDICIONES GENERALES SEGITEC COLOMBIA SAS 20-9-2023.pdf" target="__blank">Condiciones generales</a>
               <p>|</p>
               <a className="hover:text-blue-900 text-center" href="/POLITICA DE PRIVACIDAD SEGITEC COLOMBIA 20-9-2023.pdf" target="__blank">Política de privacidad</a>
               
             </div>
             <div className="flex flex-row w-full mt-2 space-x-2 justify-center">
               <a className="hover:text-blue-900 text-center content-center" href="https://www.facebook.com/profile.php?id=61551626925221" target="__blank">
                  <div className="flex flex-col items-center space-x-2">
                     <p>Facebook</p>
                     <FaFacebookSquare/>
                  </div>
               </a>
               <p>|</p>
               <a className="hover:text-blue-900 text-center content-center" href="https://www.linkedin.com/company/segitec-colombia-sas/" target="__blank">
                  <div className="flex flex-col items-center space-x-2">
                  <p>LinkedIn</p>
                  <FaLinkedin/>
                  </div>
               </a>
               <p>|</p>
               <a className="hover:text-blue-900 text-center content-center" href="https://instagram.com/segitec_colombia?igshid=NzZlODBkYWE4Ng==" target="__blank">
                  <div className="flex flex-col items-center space-x-2">
                     <p>Instagram</p>
                     <FaInstagram/>
                  </div>
               </a>
               
             </div>
        </div>
    )
}export default Footer;