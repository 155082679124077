import React, { useEffect, useState } from "react";
import info from "../../assets/Experience/experience.json"

const images = require.context('./../../assets/Projects', true);
const imageList = images.keys().map(image => images(image));

export default function ExperienceCountry({name, text}){
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % info.length);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [activeIndex]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / info.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out", 
        display: "flex",
        width: `${info.length * 100}%`,
    }
    return (
        <div className="text-left space-y-2 py-5">
            <p className="text-2xl font-normal">{name}</p>
            <div className="w-auto h-[350px] overflow-hidden relative ">
                {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
                <div style={carouselStyle}>
                    {info.map((p)=>
                        <div className="relative w-full h-[350px]"> 
                            <img className="w-full h-[350px] object-cover" style={{ flex: `0 0 ${100 / info.length}%` }} src={"./Experiencia/"+p.img+".jpg"}></img>
                            <div className="absolute w-full h-[350px] top-0">
                                <div className="sm:absolute h-[350px] bg-black/60 sm:bg-transparent sm:h-auto sm:bottom-0 sm:py-0 w-full text-gray-200">
                                    <h1 className="text-lg sm:text-xl font-bold sm:bg-black/60 px-10 pt-1 w-full">{p.proyecto}, {p.ciudad}</h1>
                                    <p className="text-md sm:text-lg sm:bg-black/60 px-10 pb-5 w-full">{p.descripcion}</p>
                                </div>
                            </div>
                        </div>)}
                </div>
                <button className="absolute left-3 top-0 bottom-0 text-4xl text-gray-500" onClick={()=>setActiveIndex((prevIndex) => (prevIndex - 1) % imageList.length)}>{"<"}</button>
                <button className="absolute right-3 top-0 bottom-0 text-4xl text-gray-500" onClick={()=>setActiveIndex((prevIndex) => (prevIndex + 1) % imageList.length)}>{">"}</button>
            </div>
            <p className="font-light">
                {text}
            </p>
        </div>
    )
}