import React ,{useState, useEffect}from "react";
import Img_Sistema_S from "./../../assets/Products/Redes seguridad/Sistema S_001.jpg";
import Img_Sistema_U from "./../../assets/Products/Redes seguridad/Sistema U_001.png";
import Img_Sistema_V from "./../../assets/Products/Redes seguridad/Sistema V_001.png";
import Img_Sistema_Mamparas from "./../../assets/Products/Redes seguridad/Vista mamparas_001.png";

const cRedes = [
    {
        src:Img_Sistema_S,
        name:"Sistema S"
    },
    {
        src:Img_Sistema_U,
        name:"Sistema U"
    },
    {
        src:Img_Sistema_V,
        name:"Sistema V"
    },
    {
        src:Img_Sistema_Mamparas,
        name:"Sistema de mamparas"
    }
]
export default function Images_red(){
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % cRedes.length);
        }, 4000);

        return () => clearTimeout(timeout);
    }, [activeIndex, cRedes.length]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / cRedes.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out",
        display: "flex",
        width: `${cRedes.length * 100}%`,
    }
    return (
        <div className="w-full md:w-[50%] h-[300px] md:pl-2">
            <div className="w-full overflow-hidden relative items-center">
                <div className=""style={carouselStyle}>
                    {cRedes.map((p)=>
                       { return (<div className="relative" style={{ flex: `0 0 ${100 / cRedes.length}%` }}>
                            <img className=" w-full object-cover h-[280px]"  src={p.src}></img>
                            <p className="absolute w-full bottom-0  font-normal text-lg text-left pl-10 py-4 bg-white/60">{p.name}</p>
                        </div>)}
                    )}
                </div>
            </div>
        </div>
    )
}