import React, {useEffect} from "react";
import img_ from "./../assets/PQRs.jpg";
export default function PQRs(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div >
            <img className="w-full min-h-[300px] object-cover" style={{height:'50vh'}} src={img_}></img>
            <div className="px-[40px] sm:px-[50px] text-left pt-5 pb-10">
            <p className="text-3xl font-normal pb-2">Peticiones Quejas y Reclamos:</p>
            <p className="text-lg">
            Si tiene algo para decirnos, si quiere enviar una queja, si quiere felicitarnos, si quiere contratar nuestros servicios o adquirir los bienes que distribuimos, por favor escríbanos a esta dirección electrónica <a href="mailto:info@segitec.com.co" className="text-blue-800 hover:text-blue-900">info@segitec.com.co</a>
            </p>
            </div>
        </div>
    )
}