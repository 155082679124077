import React, { useEffect, useState } from "react";

import proy from "./../assets/Experience/experience.json"

const images = require.context('./../assets/Projects', true);
const imageList = images.keys().map(image => images(image));

var arr = [];
while(arr.length < 6){
    var r = Math.floor(Math.random() * (proy.length-1)) + 1;
    if(arr.indexOf(r) === -1) arr.push(r);
}

/*Pendiente seleccion de imagenes y establecer ruteo*/
function Carousell_Projects(){
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        }, 7000);

        return () => clearTimeout(timeout);
    }, [activeIndex]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / imageList.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out", 
        display: "flex",
        width: `${imageList.length * 100}%`,
    }
    return (
        <div className="w-auto h-[350px] overflow-hidden relative ">
            {/*"display": "flex","transition": "transform 3s ease-in-out "*/}
            <a href="./experiencia" style={carouselStyle}>
                {arr.map((p)=>
                    <div className="relative w-full h-[350px]"> 
                        <img className="w-full h-[350px] object-cover" style={{ flex: `0 0 ${100 / arr.length}%` }} src={"./Experiencia/"+proy[p].img+".jpg"}></img>
                        <div className="absolute w-full h-[350px] top-0 ">
                            <div className="sm:absolute sm:bottom-10 bg-black/50 text-white px-10 py-5 sm:py-0 w-full">
                                <h1 className="text-lg sm:text-xl font-bold pt-1">{proy[p].proyecto}, {proy[p].ciudad}</h1>
                                <p className="text-md sm:text-lg">{proy[p].descripcion}</p>
                            </div>
                        </div>
                    </div>)}
            </a>
            <div className="absolute w-full bottom-0 bg-transparent sm:bg-black/50 py-2 text-center">
                <div className="w-[240px] sm:w-[400px] right-0 left-0 mx-auto space-x-2">
                    {Array.from({length:imageList.length},(_,_index)=>{
                       return <button onClick={()=>setActiveIndex(_index)}><div className={`h-[5px] w-[30px] sm:w-[36px] ${ _index===activeIndex? 'bg-gray-700 sm:bg-gray-200':'bg-gray-300 sm:bg-gray-500'} rounded`}/></button>
                    })}
                </div>
            </div>
        </div>
    )
}export default Carousell_Projects;