import React ,{useEffect}from "react";
import C1 from "./../assets/products.jpg";
import C3 from "./../assets/Products/Redes seguridad/Sistema S_001.jpg";
import C2 from "./../assets/workwus.png";

import Images from "../components/History/Images";


export default function Us(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="flex flex-wrap w-full px-[40px] sm:px-[50px] py-5">
            <Images C1={C1} C2={C2} C3={C3} contain={'contain2'} />
            <div className="py-1 text-justify">
                <h1 className="text-3xl font-normal py-2">Sobre Nosotros</h1>
                <p className="font-ligth py-1">
                <b>SEGITEC S.A.S.</b>, es una empresa de origen español y sólida presencia en Colombia hace más de 10 años; es precursora en la introducción de sistemas de protección colectiva y nuestro firme compromiso con la seguridad nos mantiene a la vanguardia en la entrega de soluciones innovadoras. Guiados por un firme compromiso hacia la seguridad y la constante innovación, forjamos un camino de excelencia que resguarda vidas y transforma el panorama laboral.
                </p>
                <div className="md:flex-row md:flex md:space-x-5">
                <div>
                <p className="text-2xl font-normal py-2">Misión</p>
                <p className="font-ligth py-1">
                Nos esforzamos por ser pioneros en un mundo en el que los trabajos en altura se realicen con total seguridad y confianza. En el corto plazo SEGITEC S.A.S. será quien señale el estándar de seguridad en la industria, y quien lidere el proceso de universalización en el uso de los sistemas de protección colectiva. SEGITEC S.A.S. pretende ser reconocida como líder en la protección de vidas, transformando los lugares de trabajo en espacios seguros y libres de riesgos.
                </p>
                </div>
                <div>
                <p className="text-2xl font-normal py-2">Visión</p>
                <p className="font-ligth py-1">
                La misión de SEGITEC S.A.S. es brindar a nuestros clientes soluciones de seguridad de vanguardia para proteger a los trabajadores en entornos de altura. Nos dedicamos a diseñar, instalar y mantener sistemas de protección colectiva que cumplen con los más altos estándares de calidad y seguridad, contribuyendo con la prevención de accidentes y con el bienestar de quienes confían en nuestra experiencia.
                </p>
                </div>
                </div>
                <p className="text-2xl font-normal pt-5">Historia</p>
                <p className="text-md font-ligth pt-2">
                    <a href="https://segitec.es/" target="_blank" className="font-normal text-blue-950 hover:text-blue-800">SEGITEC SEGURIDAD Y SALUD S.L.</a> inicia su trayectoria profesional en 2009, con el objetivo principal de proveer de soluciones en el ámbito de la prevención, ampliando paulatinamente sus servicios para cubrir todas las necesidades que reclama el sector. Entre estos servicios destacan las soluciones en implantación de protecciones colectivas, líneas de vida, realización de planes y estudios de seguridad y salud, coordinación de obras, técnicos de prevención, recursos preventivos, formación… 
                    <br style={{display:"block", content:"", margin:"5px"}}/>
                    En marzo de 2013 SEGITEC llega a Colombia de la mano de sus fundadores, JOSÉ MANUEL VÁZQUEZ y EDUARDO PLA, y se convierte en pionera dentro del país en la implantación de protecciones colectivas en edificaciones.
                    <br style={{display:"block", content:"", margin:"5px"}}/>
                    En 2023 sus fundadores introducen cambios sustanciales a la empresa para ampliar dentro de Colombia su plataforma de servicios, incluyendo el suministro de elementos de protección personal (EPP’s), líneas de vida provisionales (rígidas y flexibles) y definitivas, puntos de anclaje, y otras soluciones de seguridad en los procesos constructivos e industriales. 
                    <br style={{display:"block", content:"", margin:"5px"}}/>
                    Hoy en Colombia SEGITEC es líder en protecciones colectivas, líneas de vida, puntos de anclaje y elementos de protección personal (EPP’s), y está abriéndose paso en el mercado de barandillas de seguridad, redes especiales para diversos usos, etc.
                </p>
            </div>
        </div>
    )
}