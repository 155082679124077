import React from "react";
import logo from "./../assets/logo.jpg"
import { Link } from "react-router-dom";

export default function ProductHover({product_name, src_img, link}){
    return(
        <div className="relative h-[265px] min-w-[200px] 2xl:w-[300px]  2xl:h-[300px] rounded-[10px] group/item">
            <img className="absolute top-0 w-full h-full object-cover rounded-[10px]" src={src_img}></img>
            <div className="absolute top-0 w-full h-full bg-[#D9D9D9]/80 group-hover/item:bg-[#D9D9D9]/30 rounded-[10px]"></div>
            <h3 className="absolute text-center group-hover/item:bottom-12 pt-3 group-hover/item:pt-1 text-2xl w-full font-normal group-hover/item:bg-[#D9D9D9]/80 group-hover/item:text-lg px-2 ">{product_name}</h3>
            <div className="absolute bottom-0 py-4 group-hover/item:py-1 w-full group-hover/item:bg-[#D9D9D9]/80 rounded-b-[10px]">
                <Link to={link}><button className="right-0 left-0  mx-auto p-2 bg-blue-900 hover:bg-blue-950 text-white font-normal rounded-md w-[80px]">Ver Más</button></Link>
            </div>
        </div>
    )
}