import React from "react";
import {FaLocationDot} from './../../node_modules/react-icons/fa6';
import {FaHelmetSafety} from './../../node_modules/react-icons/fa6';
import {AiTwotonePhone,AiOutlineWhatsApp} from './../../node_modules/react-icons/ai';
import {FiMail} from './../../node_modules/react-icons/fi';
export default function Contact({location, name, number, mail,mail2,mail3,number2, whatsapp}){
    return(
        <div className="flex flex-col min-w-[250px] w-[400px] ">
            <div className="flex flex-row items-center space-x-2">
                <FaLocationDot/>
                <p>{location}</p>
            </div>
            {name&&<div className="flex flex-row items-center space-x-2">
                <FaHelmetSafety/>
                <p>{name}</p>
            </div>}
            <div className="flex flex-row items-center space-x-2">
                <AiTwotonePhone/>
                <p>{number}</p>
            </div>
            {number2 && (
                <div className="flex flex-row items-center space-x-2">
                <AiTwotonePhone/>
                <p>{number2}</p>
            </div>
            )}
            <div className="flex flex-row items-center space-x-2">
                <FiMail/>
                <a href={`mailto:${mail}`} className="hover:text-blue-900">{mail}</a>
            </div>
            {mail2 && (
                <div className="flex flex-row items-center space-x-2">
                <FiMail/>
                <a href={`mailto:${mail2}`} className="hover:text-blue-900">{mail2}</a>
            </div>
            )}
            {mail3 && (
                
                <div className="flex flex-row items-center space-x-2">
                <FiMail/>
                <a href={`mailto:${mail3}`} className="hover:text-blue-900">{mail3}</a>
            </div>
            )}
            {whatsapp && (
                <div className="flex flex-row items-center space-x-2">
                <AiOutlineWhatsApp/>
                <a href={`https://wa.me/${whatsapp.split(' ').join('')}`} target="__blank" className="hover:text-blue-900">{whatsapp}</a>
            </div>
            )}
        </div>
    )
}