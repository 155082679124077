import React, {useEffect} from "react";
import Dispositivos from "./Dispositivos";
import Img_Arnes from "./../../assets/Products/Arnes.png";
export default function SistemasIndividuales(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="px-[30px] sm:px-[40px] md:px-[50px] text-left overflow-hidden pb-2">
            <h1 className="font-normal text-3xl py-5 text-left">Sistemas de protección individuales</h1>
            <div className="flex flex-wrap pl-2 py-4"> 
                <div className="w-full lg:w-[50%] md:pr-2">
                    <p className="font-normal text-2xl ">Dispositivos de seguridad</p>
                    <p className="">Los dispositivos de seguridad son elementos que permiten la ejecución de trabajos en alturas mitigando el riesgo de caída. Estos dispositivos son usualmente individuales y definitivamente portátiles.</p>
                </div>
                <Dispositivos/>
            </div>
            <hr></hr>
            <div className="pl-2 py-4">
                <div className="w-full md:pr-2">
                    <p className="font-normal text-2xl ">Elementos de protección personal</p>
                    <p className="pb-2">Los ELEMENTOS DE PROTECCIÓN PERSONAL están diseñados para mitigar el riesgo que corren los usuarios que ejecutan actividades bajo condiciones que implican riesgo de caída. </p>
                </div> 
                <div className="flex flex-wrap">
                <img className="w-full sm:w-[50%] h-[300px] sm:pr-2 object-contain" src={Img_Arnes}></img>
                <div className="flex flex-col w-full sm:w-[50%] sm:pl-2 py-2 sm:pb-0">
                    <p className="font-normal text-xl">ARNÉS MGB</p>
                    <p className="">Diseñado y elaborado para el cumplimiento de la Norma EN361 Arnés anticaídas MGB para ser utilizado en todos los ámbitos de los trabajos en altura según la norma  EN 361:2002.
                    <br style={{display:"block", content:"", margin:"5px"}}/>
                    Dotado de dos puntos de enganche anticaída de anillo D, dorsal y pectoral, con una fuerza de 25kN.
                    <br style={{display:"block", content:"", margin:"5px"}}/>
                    Correas de poliéster de 45 mm ajustables en pecho y perneras.
                    </p>
                </div>
                </div>
            </div>
         </div>
    )
}