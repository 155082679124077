import React, {useEffect} from "react";
import ProductsPrincipal from "../components/Principal/ProductsPrincipal";
import img_ from "./../assets/products.jpg"

export default function Products(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <img className="w-full min-h-[300px] object-cover" style={{height:'50vh'}} src={img_}></img>
            
            <ProductsPrincipal/>
        </div>
    )
}