import React, {useEffect} from "react";
import ExperienceCountry from "../components/Experience/ExperienceCountry";

const text= ["Generamos seguridad con nuestros sistemas certificados y respaldados por más de 10 años de experiencia en Colombia con proyectos en 9 departamentos diferentes. Hemos brindado soluciones en implantación de protecciones colectivas, líneas de vida, realización de planes y estudios de seguridad y salud, coordinación de obras, técnicos de prevención, recursos preventivos, entre otros.","Generamos seguridad con nuestros sistemas certificados y respaldados por más de 10 años de experiencia en Colombia con proyectos en 9 departamentos diferentes. Hemos brindado soluciones en implantación de protecciones colectivas, líneas de vida, realización de planes y estudios de seguridad y salud, coordinación de obras, técnicos de prevención, recursos preventivos, entre otros."]
export default function Experience(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="px-[40px] sm:px-[50px] pt-5">
            <p className="text-3xl font-normal text-left">Experiencia</p>
            <ExperienceCountry name={"Colombia"} text={text[0]}/>
        </div>
    )
}