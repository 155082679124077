import React from "react";
import Carousell_Marcas from "./Carousell_Marcas";

export default function TrustInUs(){
    return(
        <div className="w-full text-left">
            <p className="text-3xl font-normal mx-[40px] sm:mx-[50px]">Confían en nosotros:</p>
            <div className="w-full h-[144px] bg-[#D9D9D9]">
                <Carousell_Marcas/>
            </div>
        </div>
    )
}