import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Principal from './pages/Principal';
import Us from './pages/Us';
import React, { useState } from 'react';
import { Route, Router, Routes,BrowserRouter  } from 'react-router-dom';
import Experience from './pages/Experience';
import Regulations from './pages/Regulations';
import WorkUs from './pages/WorkUs';
import PQRs from './pages/PQRs';
import SistemasColectivos from './components/Products/SistemasColectivos';
import SistemasIndividuales from './components/Products/SistemasIndividuales';
import RedesOtrosProp from './components/Products/RedesOtrosProp';
import Products from './pages/Products';
import Catalogs from './pages/Catalogs';
import Catalog from './pages/Catalog';
import Certificate from './pages/Certificate';
import { FaWhatsapp } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";


function App() {
  
  const [closeButton, setClose] = useState(false);
  return (
    <div className="App font-['Roboto'] font-light">
      
      <BrowserRouter >
        <Navbar/>
        <div className="min-h-[500px]">
          <div className="fixed bottom-5 right-10 z-50">
                <a href={`https://wa.me/+573163042684`} target="__blank"  className='flex h-[50px] w-[50px] sm:w-[300px] text-lg rounded-3xl bg-[#25D366] hover:bg-[#075E54] text-white font-normal justify-center sm:space-x-2 items-center shadow-md shadow-[#128C7E]'>
                  <p className='hidden sm:flex'>Contactenos por WhatsApp</p>
                  <FaWhatsapp size={25}/>
                </a>              
          </div>
          <div className={`${closeButton?'sm:hidden':''} hidden sm:flex fixed bottom-20 right-10 z-50`}>
                {/**<IconContext.Provider value={{ color: "black"}}  >
                  <button className='absolute bottom-7 right-0' onClick={()=>setClose(true) }>
                    <IoMdCloseCircle size={22} className='bg-gray-200 rounded-3xl'/>
                  </button>
  </IconContext.Provider>**/}
                <a href={`https://wa.me/+573174235983`} target="__blank"  className='flex h-[40px] w-[100px] sm:w-[450px] text-md rounded-3xl bg-gray-400 hover:bg-[#075E54] text-white font-normal justify-center sm:space-x-2 items-center shadow-md shadow-gray-400'>
                  <p className='hidden sm:flex'>¿Interesado en trabajar con nosotros? Envía tu hoja de vida</p>
                </a>              
          </div>
          <Routes >
            <Route index element={<Principal/>}/>
            <Route path='Nosotros' element={<Us/>}/>
            <Route path='Experiencia' element={<Experience/>}/>
            <Route path='Normatividad' element ={<Regulations/>}/>
            <Route path='Trabaja_con_nosotros' element={<WorkUs/>}/>
            <Route path='Productos' element={<Products/>}/>
            <Route path='Catalogos' element={<Catalogs/>}/>
            <Route path='Certificados' element={<Certificate/>}/>
            <Route path='Documentos/:url' element={<Catalog/>}/>
            <Route path='PQRs' element={<PQRs/>}/>
            <Route path='Productos/Sistemas_Colectivos' element={<SistemasColectivos/>}/>
            <Route path='Productos/Sistemas_Individuales' element={<SistemasIndividuales/>}/>
            <Route path='Productos/Redes_Otros_Propositos' element={<RedesOtrosProp/>}/>
          
          </Routes>
          </div>
        <Footer/>
      </BrowserRouter >
    </div>
  );
}

export default App;
