import React, {useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import { pdfjs,
     Document, Page } from 'react-pdf';
     import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

export default function Catalog(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    const {url} = useParams();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    return (
        <div className="w-full ">
            {/*<iframe src={`http://flowpaper.com/flipbook/https://fluffy-truffle-f6ed71.netlify.app/Catalogos/Textil/${url}`}
            width="100%" height="800" className="w-full" allowFullScreen>
    </iframe>*/}
            <div className="w-full flex flex-row bg-gray-700 justify-center">
            {/*url:"https://fluffy-truffle-f6ed71.netlify.app/Catalogos/Textil/"+url
            <Page pageNumber={pageNumber} renderAnnotationLayer={false}></Page>
            <Document
            file={"/Catalogos/Textil/"+url}
            onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}
            renderMode="canvas"
            >
                <div className="w-full flex flex-row items-center justify-center ">
                <HTMLFlipBook width={500} height={1000} className="" style={{position:"relative"}}>
                    <div ><Page height={1000} width={500} pageNumber={pageNumber} renderAnnotationLayer={false} style={{position:"relative"}}></Page></div>
                    <div><Page height={1000} width={500} pageNumber={pageNumber+1} renderAnnotationLayer={false} style={{position:"relative"}}></Page></div>
                    <div><Page height={1000} width={500} pageNumber={pageNumber+1} renderAnnotationLayer={false} style={{position:"relative"}}></Page></div>
                </HTMLFlipBook>
                </div>
                    <div className="w-full flex flex-row justify-center">
                       
                            
                    </div>
                    
            </Document>
            */}
            
            </div>
            <object data={"./../Docs/"+url+"#toolbar=0"} type="application/pdf" width="100%" style={{height:'85vh'}}>
            </object>
            {console.log("/"+url)}
            {/*<div className="w-full flex flex-row items-center justify-center ">
            <HTMLFlipBook width={500} height={1000}>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
            </HTMLFlipBook>
            </div>*/}
            <div id='div-id'></div>
            
        </div>
    )
}
