import React ,{useState, useEffect}from "react";

export default function Images({C1, C2, C3, contain}){
    const [activeIndex, setActiveIndex] = useState(0);
    const imageList =[C1,C2,C3];
    useEffect(() => {
        const timeout = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [activeIndex, imageList.length]);

    const carouselStyle ={
        transform: `translateX(-${(activeIndex / imageList.length) * 100}%)`,
        transition: "transform 1.5s ease-in-out",
        display: "flex",
        width: `${imageList.length * 100}%`,
    }
    return (
        <div className="w-full">
            <div className="hidden sm:flex w-full overflow-hidden space-x-2 " style={{height:"60vh"}}>
                <img className={`w-[50%] h-full ${contain==='contain1'?'object-contain':'object-cover'}`} src={C1}></img>
                <div className="w-[50%] flex flex-col space-y-2">
                    <img className={`h-[50%] w-full ${contain==='contain2'?'object-contain':'object-cover'}`} src={C2}></img>
                    <img className={`h-[50%] w-full ${contain==='contain3'?'object-contain':'object-cover'}`} src={C3}></img>
                </div>
            </div>
            <div className="w-full sm:hidden overflow-hidden relative items-center ">
                <div style={carouselStyle}>
                    {imageList.map((p)=>
                        <div className="relative w-full h-[200px]">
                            <img className="w-full object-cover h-[200px]"style={{ flex: `0 0 ${100 / imageList.length}%` }}  src={p}></img>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}