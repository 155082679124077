import React,{useEffect} from "react";
import Carousell from "../components/Principal/Carousell";
import ProductsPrincipal from "../components/Principal/ProductsPrincipal";
import ProjectsPrincipal from "../components/Principal/ProjectsPrincipal";
import TrustInUs from "../components/Principal/TrustInUs";
import Carousell_Exclusive from "../components/Principal/Carousell_Exclusive";
import img_ from "../assets/DISTRIBUIDOR.png";

function Principal(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div className="font-sans items-center">
            <Carousell/>
            <ProductsPrincipal/>
            <hr className="my-2 mx-[40px] sm:mx-[50px]"></hr>
            <a href="https://multigarben.com" target="__blank"><img className="min-h-[150px] max-h-[350px] aspect-[5/1] w-full object-cover"  src={img_}></img></a>
            <hr className="my-2 mx-[40px] sm:mx-[50px]"></hr>
            <ProjectsPrincipal/>
            <TrustInUs/>
            <Carousell_Exclusive/>
        </div>
    )

}export default Principal;